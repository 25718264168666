import { template as template_7ee8b21d0b044b27a8ab349e8d547840 } from "@ember/template-compiler";
const SidebarSectionMessage = template_7ee8b21d0b044b27a8ab349e8d547840(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
