import { template as template_9bb775a2a3964b6591c7fac61efdfc78 } from "@ember/template-compiler";
const FKControlMenuContainer = template_9bb775a2a3964b6591c7fac61efdfc78(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
