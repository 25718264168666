import { template as template_fae16e61585b4d5da1129cd560cb6876 } from "@ember/template-compiler";
const FKText = template_fae16e61585b4d5da1129cd560cb6876(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
